import { ref, reactive } from 'vue';
import FormConfig from '@/utils/DynamicForm';
import { batchFormType } from '@/constant/form';
import { batchFieldList } from '../../../api';

const FormData = () => {
  const form = ref({
    goodsCode: null,
  });
  const fields = ref([]);

  const formRules = reactive({
    goodsCode: [
      { required: true, message: '货品编码为必须录入项', trigger: 'blur' },
    ],
  });
  const setFields = async (formData = {}) => {
    const result = await batchFieldList({});
    fields.value = result.map((item) => {
      const type = batchFormType[item.component] || batchFormType.input;
      const { component, form: fromItem = {} } = FormConfig[type](item, formData, {
        label: 'fieldName',
        prop: 'fieldCode',
      });
      form.value = {
        ...formData,
        ...fromItem,
      };
      return {
        ...item,
        ...component,
      };
    });
  };
  const setFieldsForm = async (info = {}) => {
    form.value = {
      ...info,
    };
    await setFields(info);
  };
  const removeForm = () => {
    form.value = {
      goodsCode: null,
    };
    fields.value = [];
  };
  return {
    form,
    fields,
    setFieldsForm,
    removeForm,
    formRules,
  };
};

export default FormData;
