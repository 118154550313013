import { ref, reactive } from 'vue';
import GoodsList from './GoodsList';
import FormData from './formData';
import { batchStockQuery } from '../../../api';

const useDrawer = () => {
  const visible = ref(false);
  const loading = reactive({
    open: false,
    submit: false,
  });
  const {
    data: tableData,
    setGoods,
    removeGoods,
  } = GoodsList();
  const {
    form,
    fields,
    setFieldsForm,
    removeForm,
    submitForm,
  } = FormData();

  /**
   * @param {*} list 勾选的数据
   * @param {*} formFields 批属性form表单集合
   */
  const open = async (list) => {
    const { goodsId, shelfLife } = list[0] || {};
    loading.open = true;
    try {
      const { details = [], ...info } = await batchStockQuery({
        goodsId,
        batchStockQueryDataList: list.map((item) => ({
          locationId: item.locationId,
          locationCode: item.locationCode,
          batchNo: item.batchNo,
          goodsQuality: item.goodsQuality,
          productDate: item.stockProductDate,
        })),
      });
      // 获取批属性表单
      await setFieldsForm({ ...info, shelfLife });
      await setGoods(details);
      visible.value = true;
    } finally {
      loading.open = false;
    }
  };
  const close = () => {
    removeGoods();
    removeForm();
    visible.value = false;
  };
  const onSubmit = async () => {
    loading.submit = true;
    try {
      await submitForm(tableData);
    } finally {
      loading.submit = false;
    }
  };

  return {
    visible,
    loading,
    open,
    close,
    onSubmit,
    tableData,
    form,
    fields,
  };
};

export default useDrawer;
