import { ref, unref, watch } from 'vue';
import moment from 'moment';
import FormConfig from '@/utils/DynamicForm';
import { batchFormType } from '@/constant/form';
import { YMD } from '@/constant/timeFormat';
import IS_RETURN from '../constant';
import { batchAttributeByGoods, adjustmentConfirm } from '../../../api';
// 到期日期 = 生产日期 + 保质期
const calculateExpireDate = ({ productDate, shelfLife }) => moment(productDate).add(shelfLife, 'days').format(YMD);
// 生产日期 = 到期日期 - 保质期
const calculateProductDate = ({ expireDate, shelfLife }) => moment(expireDate).subtract(shelfLife, 'days').format(YMD);
const FormData = () => {
  const form = ref({});
  const fields = ref([]);

  const setFields = async ({ goodsId }, formData = {}) => {
    const { batchAttribute = [] } = await batchAttributeByGoods({ goodsId }) || {};
    fields.value = batchAttribute.map((item) => {
      let type;
      let config = {};
      if (item.fieldCode === 'is_return') {
        type = batchFormType.select;
        config = {
          ...item,
          options: [{
            value: IS_RETURN.TRUE,
            label: '是',
          }, {
            value: IS_RETURN.FALSE,
            label: '否',
          }],
          formItemAttrs: {
            rules: [{ required: true, message: `请选择${item.fieldName}`, trigger: 'change' }],
          },
        };
      } else if (item.fieldCode === 'batch_reverse') {
        type = batchFormType.radioGroup;
        config = {
          ...item,
          options: [{
            value: IS_RETURN.TRUE,
            label: '是',
          }, {
            value: IS_RETURN.FALSE,
            label: '否',
          }],
          formItemAttrs: {
            rules: [{ required: true, message: `请选择${item.fieldName}`, trigger: 'change' }],
          },
        };
      } else if (item.fieldCode === 'expire_date') { // 到期日期
        type = batchFormType[item.component] || batchFormType.input;
        config = {
          ...item,
          componentAttrs: {
            disabledDate: (date) => {
              if (!form.value.shelfLife) return false;
              const today = moment();
              const maxDate = moment(today).add(form.value.shelfLife, 'days').format(YMD);
              // 到期日期
              return moment(maxDate).isBefore(date.getTime());
            },
          },
          formItemAttrs: {
            rules: [{ required: true, message: `请选择${item.fieldName}`, trigger: 'change' }],
          },
        };
      } else if (item.fieldCode === 'productDate') { // 生产日期
        type = batchFormType[item.component] || batchFormType.input;
        config = {
          ...item,
          componentAttrs: {
            disabledDate: (date) => {
              const today = moment();
              return moment(today).isSameOrBefore(date.getTime());
            },
          },
          formItemAttrs: {
            rules: [{ required: true, message: `请选择${item.fieldName}`, trigger: 'change' }],
          },
        };
      } else {
        type = batchFormType[item.component] || batchFormType.input;
        config = {
          ...item,
          formItemAttrs: {
            rules: [{ required: true, message: `请输入${item.fieldName}`, trigger: 'change' }],
          },
        };
      }
      const { component, form: fromItem = {} } = FormConfig[type](config, formData, {
        label: 'fieldName',
        prop: 'fieldCode',
      });
      form.value = {
        ...formData,
        ...fromItem,
      };
      return {
        ...item,
        ...component,
      };
    });
  };
  const setFieldsForm = async (info = {}) => {
    form.value = {
      ...info,
    };
    await setFields({ goodsId: info.goodsId }, info);
  };
  const removeForm = () => {
    form.value = {};
    fields.value = [];
  };
  const submitForm = async (tableData) => {
    const toBatchAttribute = [];
    fields.value.forEach((item) => {
      if (form.value[item.prop]) {
        toBatchAttribute.push({
          fieldCode: item.fieldCode,
          fieldName: item.fieldName,
          component: item.component,
          paddingValue: form.value[item.prop],
        });
      }
    });
    const data = {
      ...unref(form),
      details: unref(tableData),
      toBatchAttribute,
    };
    await adjustmentConfirm(data);
  };

  watch(
    () => form.value.expire_date,
    () => {
      if (!form.value.expire_date || !form.value.shelfLife) return;
      form.value.productDate = calculateProductDate({
        expireDate: form.value.expire_date,
        shelfLife: form.value.shelfLife,
      });
    },
  );
  watch(
    () => form.value.productDate,
    () => {
      if (!form.value.productDate || !form.value.shelfLife) return;
      form.value.expire_date = calculateExpireDate({
        productDate: form.value.productDate,
        shelfLife: form.value.shelfLife,
      });
    },
  );

  return {
    form,
    fields,
    setFieldsForm,
    setFields,
    removeForm,
    submitForm,
  };
};

export default FormData;
