import { ref, reactive } from 'vue';
import AdjustList from './adjustList';
import SubForm from './formData';

const useDrawer = () => {
  const visible = ref(false);
  const loading = reactive({
    open: false,
    submit: false,
  });
  const {
    data: tableData,
    getAdiustList,
    removeAdjust,
  } = AdjustList();
  const {
    form,
    fields,
    setFieldsForm,
    removeForm,
    formRules,
  } = SubForm();
  const handleSearch = async (formData, fieldesAttr) => {
    loading.submit = true;
    try {
      await getAdiustList(formData, fieldesAttr);
    } finally {
      loading.submit = false;
    }
  };
  const open = async ({ goodsCode } = {}) => {
    visible.value = true;
    loading.open = true;
    try {
      // 获取批属性表单
      await setFieldsForm({ goodsCode });
      if (goodsCode) {
        handleSearch({ goodsCode }, fields.value);
      }
    } finally {
      loading.open = false;
    }
  };
  const close = () => {
    removeAdjust();
    removeForm();
    visible.value = false;
  };

  return {
    visible,
    loading,
    open,
    close,
    getAdiustList,
    handleSearch,
    tableData,
    form,
    fields,
    formRules,
  };
};

export default useDrawer;
