import { formatKeyValueObject } from '@/utils/base';

export const FROZEN_STATUS = [
  {
    value: 'FROZEN',
    label: '冻结',
  },
  {
    value: 'UNFROZEN',
    label: '解冻',
  },
];

// 温层
export const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};

export const ALERT_OPTIONS = [
  {
    value: 'FIRST',
    label: '首次预警',
    color: '#FFCE83',
  },
  {
    value: 'SECOND',
    label: '二次预警',
    color: '#FF9D07',
  },
  {
    value: 'STOP_WARNING',
    label: '停止发货预警',
    color: '#F56C6C',
  },
  {
    value: 'STOP',
    label: '已过期',
    color: '#909399',
  },
  {
    value: 'NORMAL',
    label: '正常',
    color: '#07C160',
  },
];

export const ATTRIBUTE_TYPE = {
  INPUT: 'input',
  DATE: 'date',
};

export const ALERT_ENUM = formatKeyValueObject(ALERT_OPTIONS, { value: 'value', label: 'color' });
