<template>
  <nh-drawer
    v-model="visible"
    :before-close="close"
    destroy-on-close
    :append-to-body="true"
    size="1111px"
    title="历史批次查询"
  >
    <div
      v-loading="loading.open"
      class="content"
    >
      <div class="form">
        <el-form
          ref="ruleFormRef"
          :model="form"
          label-width="120px"
          :rules="formRules"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item
                label="货品编码"
                prop="goodsCode"
              >
                <el-input
                  v-model="form.goodsCode"
                  clearable
                  placeholder="支持货品编码和货主货品编码"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col
              v-for="item in fields"
              :key="item.prop"
              :span="8"
            >
              <el-form-item
                :label="item.label"
                :prop="item.prop"
              >
                <nh-form-component :form="form" :field="item" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="btn">
          <nh-button
            @click="handleReset(ruleFormRef)"
          >
            重 置
          </nh-button>
          <nh-button
            type="primary"
            @click="handleSearch(ruleFormRef)"
          >
            搜 索
          </nh-button>
        </div>
      </div>
      <div v-loading="loading.submit" class="adjust">
        <PlTable
          :data="tableData"
          :columns="tableCol"
        >
          <template #action="{row}">
            <nh-button type="text" @click="handleSelect(row)">
              选 择
            </nh-button>
          </template>
        </PlTable>
      </div>
    </div>
  </nh-drawer>
</template>

<script setup>
import {
  defineProps, defineExpose, toRefs, computed,
  defineEmits, ref, unref,
} from 'vue';
import useDrawer from './uses/drawer';
import TABLECOLUMN from './fileds';

const prop = defineProps({
  active: {
    type: Boolean,
    default: true,
  },
});
const { active } = toRefs(prop);
const emits = defineEmits(['onSelect']);
const ruleFormRef = ref(null);
const tableCol = computed(() => TABLECOLUMN(active.value));

const {
  visible,
  loading,
  open,
  close,
  fields,
  tableData,
  form,
  formRules,
  getAdiustList,
} = useDrawer();

const handleSelect = (row) => {
  emits('onSelect', row);
  close();
};
const handleSearch = async (formEl) => {
  if (formEl) {
    await formEl.validate();
    getAdiustList(unref(form), unref(fields));
  }
};
const handleReset = async (formEl) => {
  if (formEl) {
    await formEl.resetFields();
    tableData.value = [];
    await formEl.validate();
    getAdiustList(unref(form), unref(fields));
  }
};
defineExpose({
  open,
});
</script>
<style lang="scss" scoped>
.adjust {
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  .form {
    margin-bottom: 24px;

    .btn {
      text-align: right;
    }
  }

  .adjust {
    flex: 1;
  }
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ebeef5;

  & > div {
    padding-right: 30px;
  }
}
</style>
