import { FormType } from '@/constant/form';

export function frozenReasonType(FROZEN_REASON) {
  return {
    label: '冻结原因：',
    prop: 'frozenReasonType',
    colAttrs: {
      span: 24,
    },
    component: FormType.SELECT,
    subComponent: 'el-option',
    options: FROZEN_REASON,
    componentAttrs: {
      placeholder: '请选择冻结原因',
    },
  };
}
export const frozenReason = {
  label: '其他：',
  prop: 'frozenReason',
  colAttrs: {
    span: 24,
  },
  component: FormType.INPUT,
  componentAttrs: {
    maxlength: 100,
    type: 'textarea',
  },

};
