<template>
  <div class="adjustment">
    <nh-dialog
      v-model="dialogVisible"
      title="提示"
      :track="{
        name_zh: `在库管理/库存管理/库位批次库存查询/弹窗-库存冻结`,
        trackName: $route.path
      }"
    >
      <PlForm
        v-model="form"
        mode="normal"
        :fields="formFileds"
        :form-props="{rules:rules}"
        :collapse="false"
        submit-btn-text="确 定"
        reset-btn-text="取 消"
        @submit="handleSubmit"
        @reset="handleClose"
      />
    </nh-dialog>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';
import { frozenReasonType, frozenReason } from './fileds';

const OTHER_REASON = 'other_frozen';
export default {
  mixins: [loadingMixin],
  emits: ['submitSuccess'],
  data() {
    return {
      OTHER_REASON,
      dialogVisible: false,
      form: {},
      rules: {
        frozenReasonType: [{ required: true, message: '请选择', trigger: 'blur' }],
        frozenReason: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    };
  },
  computed: {
    FROZEN_REASON() {
      // 通过 dictionaryFiledMap 获取数据字典中的 FROZEN_REASON ，返回格式[{value: code, label: name}]
      return this.$dictionaryFiledMap.FROZEN_REASON || [];
    },
    formFileds() {
      if (this.form.frozenReasonType === OTHER_REASON) {
        return [frozenReasonType(this.FROZEN_REASON), frozenReason];
      }
      return [frozenReasonType(this.FROZEN_REASON)];
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    init() {
      this.form = {
        frozenReasonType: null,
        frozenReason: null,
      };
      this.dialogVisible = true;
    },
    async handleSubmit() {
      if (this.form.frozenReasonType !== OTHER_REASON) {
        this.form.frozenReason = this.FROZEN_REASON
          .find((item) => item.value === this.form.frozenReasonType).label;
      }
      this.handleClose();
      this.$emit('submitSuccess', this.form);
    },
  },
};
</script>
