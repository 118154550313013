const TABLECOLUMN = (active) => {
  const defaultTableCol = [
    {
      label: '批次号',
      prop: 'batchNo',
      width: 300,
    }, {
      label: '批属性',
      prop: 'batchAttrFields',
      minWidth: 400,
      formatter: ({ batchAttrFields }) => batchAttrFields.reduce((prev, curr) => `${prev + curr.fieldName}：${curr.paddingValue}；`, ''),
    },
  ];
  if (active) {
    defaultTableCol.push({
      label: '操作',
      prop: 'action',
      fixed: 'right',
      minWidth: 80,
    });
  }
  return defaultTableCol;
};

export default TABLECOLUMN;
