import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import unitNumber from '@/utils/unitNumber';
import {
  ALERT_OPTIONS, FROZEN_STATUS,
} from './constant';

export const TABLECOLUMN = (cols = []) => {
  const attr = cols.map((item) => ({
    label: `批属性-${item.fieldName}`,
    prop: `batch_${item.humpFieldCode}`,
    exportProp: item.humpFieldCode,
    width: 180,
    formatter: (row) => row[item.humpFieldCode],
  }));
  return [{
    type: 'selection',
    prop: 'selection',
    width: 50,
  }, {
    label: '序号',
    type: 'index',
    prop: 'index',
    width: 50,
  }, {
    label: '临期预警',
    prop: 'warnStatusDesc',
    minWidth: 180,
  }, {
    label: '货品编码',
    prop: 'goodsCode',
    minWidth: 180,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    minWidth: 120,
  }, {
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    minWidth: 180,
  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 100,
  }, {
    label: '批次号',
    prop: 'batchNo',
    minWidth: 150,
  },
  ...attr,
  {
    label: '生产日期',
    prop: 'stockProductDate',
    ...DateTime,
  }, {
    label: '到期日期',
    prop: 'stockExpireDate',
    ...DateTime,
  }, {
    label: '库区',
    prop: 'warehouseAreaName',
    width: 180,
  }, {
    label: '库位',
    prop: 'locationCode',
    width: 240,
  }, {
    label: '默认单位',
    prop: 'defaultUnit',
    width: 80,
  }, {
    label: '总库存(默认单位)',
    prop: 'defaultUnitAmount',
    width: 140,
  }, {
    label: '总库存(整单位)',
    prop: 'totalBigUnitNum',
    minWidth: 140,
    formatter: (row) => row.totalBigUnitNum + row.bigUnit,
  }, {
    label: '总库存(零单位)',
    prop: 'totalSmallUnitNum',
    minWidth: 140,
    formatter: (row) => row.totalSmallUnitNum + row.smallUnit,
  }, {
    label: '可用量',
    prop: 'availableNum',
    minWidth: 160,
    formatter: (row) => (row.availableNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.availableNum }).txt : row.availableNum),
  }, {
    label: '分配量',
    prop: 'lockedNum',
    minWidth: 160,
    formatter: (row) => (row.lockedNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.lockedNum }).txt : row.lockedNum),
  }, {
    label: '冻结量',
    prop: 'frozenNum',
    minWidth: 160,
    formatter: (row) => (row.frozenNum
      ? unitNumber.calculate({ ...row, smallUnitNumber: row.frozenNum }).txt : row.frozenNum),
  }, {
    label: '规格',
    prop: 'goodsSpecifications',
    minWidth: 80,
  }, {
    label: '换算率',
    prop: 'conversionRate',
    minWidth: 80,
  }, {
    label: '温层',
    prop: 'temperatureLayerName',
    minWidth: 160,
  }, {
    label: '货品质量',
    prop: 'goodsQuality',
    minWidth: 120,
  }, {
    label: '冻结原因',
    prop: 'frozenReason',
    minWidth: 180,
  }, {
    label: '冻结状态',
    prop: 'frozenStatusDesc',
    minWidth: 180,
  },
  {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    minWidth: 80,
  },
  ];
};
export const serchFields = (GOODS_QUALITY, zoneTemperature) => [{
  label: '货品编码',
  prop: 'goodsCode',
  component: FormType.INPUT,

}, {
  label: '货品名称',
  prop: 'goodsName',
  component: FormType.INPUT,

}, {
  label: '货主',
  prop: 'shipperId',

}, {
  label: '库区',
  prop: 'warehouseAreaId',

}, {
  label: '库位',
  prop: 'locationCode',
  component: FormType.INPUT,

}, {
  label: '货品质量',
  prop: 'goodsQuality',
  component: FormType.SELECT,
  options: GOODS_QUALITY,
},
{
  label: '批次号',
  prop: 'batchNo',
  component: FormType.INPUT,

},
{
  label: '冻结状态',
  prop: 'frozenStatus',
  component: FormType.SELECT,
  options: FROZEN_STATUS,
}, {
  prop: 'labelSelect',
},
{
  label: '临期预警',
  prop: 'warnStatus',
  component: FormType.SELECT,
  options: ALERT_OPTIONS,
}, {
  label: '温层',
  prop: 'goodsTemperatureLayer',
  component: FormType.SELECT,
  options: zoneTemperature,
},
];
