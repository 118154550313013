const TABLECOLUMN = [
  {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '库位',
    prop: 'locationCode',
    minWidth: 120,
  }, {
    label: '原批次',
    prop: 'batchNo',
    minWidth: 160,
  }, {
    label: '批属性',
    prop: 'batchAttrFieldResps',
    minWidth: 220,
    formatter: (row) => row.batchAttrFieldResps
      .reduce((prev, curr) => `${prev + curr.fieldName}：${curr.paddingValue}；`, ''),
  }, {
    label: '数量(默认单位)',
    prop: 'defaultUnitAmount',
    minWidth: 140,
  }, {
    label: '整数(整单位)',
    prop: 'wholeUnitAmount',
    minWidth: 140,
  }, {
    label: '整数(零单位)',
    prop: 'zeroUnitAmount',
    minWidth: 140,
  }];

export default TABLECOLUMN;
