import { ref } from 'vue';
import { historyBatchuery } from '../../../api';

const AdjustList = () => {
  const data = ref([]);
  const removeAdjust = () => {
    data.value = [];
  };
  const getAdiustList = async (form, fields = []) => {
    const batchAttrValues = [];
    fields.forEach((item) => {
      if (form[item.prop]) {
        batchAttrValues.push({
          fieldCode: item.fieldCode,
          fieldName: item.fieldName,
          component: item.component,
          paddingValue: form[item.prop],
        });
      }
    });

    const resp = await historyBatchuery({
      ...form,
      batchAttrValues,
    }) || {};
    data.value = resp || [];
  };
  return {
    data,
    getAdiustList,
    removeAdjust,
  };
};

export default AdjustList;
