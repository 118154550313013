<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields(GOODS_QUALITY,zoneTemperature)"
      @submit="handleQuery"
      @reset="resetQuery"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template>
      <template #warehouseAreaId>
        <el-select
          v-model="searchQuery.warehouseAreaId"
          clearable
          filterable
        >
          <el-option
            v-for="item in warehouseZonelist"
            :key="item.id"
            :label="item.zoneName"
            :value="item.id"
          />
        </el-select>
      </template>
      <template #labelSelect>
        <select-label
          v-model:labelSelectData="labelSelectData"
          :label-options="attributeCodes"
        />
      </template>
      <template #warnStatus>
        <el-select
          v-model="searchQuery.warnStatus"
          clearable
          filterable
        >
          <el-option
            v-for="item in ALERT_OPTIONS"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span class="option-left">{{ item.label }}</span>
            <span
              class="option-right option-box"
              :style="{
                backgroundColor: item.color
              }"
            />
          </el-option>
        </el-select>
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="tableCol"
      show-summary
      :summary-method="handleGetSummaries"
      :show-table-setting="true"
      :checked-columns="defaultCheckedCol"
      @selection-change="handleSelectionChange"
      @update:pagination="handlePaginationChange"
      @columnsChange="handleColumnsProp"
    >
      <template #tableHeaderRight>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[库存冻结]'
          }"
          @click="handleFrozen"
        >
          库存冻结
        </nh-button>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[库存解冻]'
          }"
          :loading="loading.handleUnfreeze"
          @click="handleUnfreeze"
        >
          库存解冻
        </nh-button>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[货品质量调整]'
          }"
          :loading="loading.handleQuality"
          @click="handleQuality"
        >
          货品质量调整
        </nh-button>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[批次调整]'
          }"
          :loading="loading.handleBatchAdjustment"
          @click="handleBatchAdjustment"
        >
          批次调整
        </nh-button>
        <nh-button
          :track="{
            trackName: '在库管理/库存管理/库位批次库存查询/[历史批次查询]'
          }"
          :loading="loading.handleBatchQuery"
          @click="handleBatchQuery"
        >
          历史批次查询
        </nh-button>
      </template>
      <template #warnStatusDesc="{row}">
        <span
          class="status-flag"
          :style="{backgroundColor: ALERT_ENUM[row.warnStatus]}"
        /> {{ row.warnStatusDesc }}
      </template>
      <template #batchNo="{row}">
        <div>
          <el-tooltip
            class="item"
            effect="dark"
            placement="top-start"
          >
            <template #content>
              批属性
              <p
                v-for="(batchFieldItem) in attributeCodes"
                :key="batchFieldItem.id"
                class="batch-field"
              >
                <span v-if="judgeBatchField(batchFieldItem.fieldCode,row.batchAttributes)">
                  {{ batchFieldItem.fieldName }}:
                  {{ formatBatchField(batchFieldItem.fieldCode,row.batchAttributes) }}
                </span>
              </p>
            </template>
            <span>{{ row.batchNo }}</span>
          </el-tooltip>
        </div>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleRecordsClick(row)">
          预占记录
        </nh-button>
      </template>
    </PlTable>
    <frozen-order-dialog ref="frozenDialog" @submitSuccess="frozenSuccess" />
    <recordsDialog ref="recordsDialog" />
    <modify-quality-dialog
      ref="modifyQualityDialog"
      :list="selectionList"
      @modifySuccess="handleModifyQuerySuccess"
    />
    <QualityAdjust ref="qualityAdjustDialog" @onSucceed="handleQuery" />
    <HistoryAdjust ref="historyAdjustDialog" :active="false" />
  </pl-block>
</template>
<script>
import tableSummary from '@/utils/tableSummary';

import loadingMixin from '@thales/loading';
import { SearchType } from '@/constant/form';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import {
  shipperGoodsStockPage,
  shipperGoodsStockExport,
  warehouseZonePage,
  batchFieldList,
  stockFreeze,
  stockUnFreeze,
  customerShowList,
} from './api';
import {
  ALERT_OPTIONS, ATTRIBUTE_TYPE, FROZEN_STATUS, ALERT_ENUM,
} from './constant';
import selectLabel from '../../components/selectLabel.vue';
import FrozenOrderDialog from './components/FrozenOrderDialog.vue';
import recordsDialog from './subPages/recordsDialog/index.vue';
import ModifyQualityDialog from './subPages/ModifyQualityDialog/index.vue';
import QualityAdjust from './components/QualityAdjust/QualityAdjust.vue';
import HistoryAdjust from './components/HistoryAdjust/HistoryAdjust.vue';
import summaryConfig from './config';

export default {
  name: 'LocationBatchQuery',
  components: {
    selectLabel,
    FrozenOrderDialog,
    recordsDialog,
    ModifyQualityDialog,
    QualityAdjust,
    HistoryAdjust,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      ALERT_OPTIONS,
      SearchType,
      FROZEN_STATUS,
      ALERT_ENUM,
      tableData: [],
      searchQuery: {
        shipperId: null,
      },
      loading: {
        getTableData: false,
        handleUnfreeze: false,
      },
      selectIds: [],
      selectionList: [],
      selectCol: [],
      defaultCheckedCol: [],
      attributeCodes: [],
      warehouseZonelist: [],
      labelSelectData: {
        labelKey: '',
        labelValue: '',
      },
      batchField: [],
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
    zoneTemperature() {
      return this.$dictionaryFiledMap.ZONE_TEMPERATURE_LAYER_CODE || [];
    },
    tableCol() {
      return TABLECOLUMN(this.batchField);
    },
  },
  created() {
    this.getWarehouseZonelist();
    this.loadBatchFieldList();
    this.getTableData();
    this.getBatchField();
    this.defaultCheckedCol = (TABLECOLUMN() || []).map((item) => item.prop || item.type);
  },
  methods: {
    async getBatchField() {
      this.batchField = await customerShowList();
    },
    async getWarehouseZonelist(word) {
      const resp = await warehouseZonePage({ page: 1, size: 1000 }, { zoneName: word || '' });
      this.warehouseZonelist = resp.records;
    },
    async loadBatchFieldList() {
      let result = await batchFieldList({});
      result = result || [];
      this.attributeCodes = result.map((item) => ({
        ...item,
        label: item.fieldName,
        value: item.fieldCode,
        type: item.component === ATTRIBUTE_TYPE.DATE
          ? this.SearchType.DATE_PICKER : this.SearchType.INPUT,
        props: {
          clearable: true,
        },

      }));
      this.labelSelectData.labelKey = this.attributeCodes[0].value;
    },
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig;
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },
    formatBatchField(code, batchAttributes) {
      return batchAttributes ? batchAttributes[code] : '';
    },
    judgeBatchField(code, batchAttributes) {
      if (batchAttributes && (batchAttributes[code] || batchAttributes[code] === '')) {
        return true;
      }
      return false;
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    resetQuery() {
      this.labelSelectData.labelValue = '';
      this.labelSelectData.labelKey = this.attributeCodes[0].value;
      this.handleQuery();
    },
    async getTableData() {
      this.selectIds = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await shipperGoodsStockPage(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handleSelectionChange(selection) {
      this.selectionList = selection;
      this.selectIds = selection.map((item) => item.id);
    },
    buildParams() {
      this.searchQuery.batchAttrName = this.labelSelectData.labelKey;
      this.searchQuery.batchAttrValue = this.labelSelectData.labelValue;
      return {
        ...this.searchQuery,
      };
    },
    handlePaginationChange() {
      this.getTableData();
    },
    // 选择的字段
    handleColumnsProp(col) {
      this.selectCol = col;
    },
    async handleExport() {
      const data = this.buildParams();
      if (this.selectIds.length > 0) {
        data.selectIds = this.selectIds;
      }
      // 导出时以选择的字段为准
      const exportCol = this.selectCol.length > 0 ? this.selectCol : this.defaultCheckedCol;
      const exportFields = exportCol.reduce((prev, curr) => {
        if (curr !== 'selection' && curr !== 'index' && curr !== 'action') {
          const findField = this.tableCol.find((item) => item.prop === curr) || {};
          return [...prev, {
            fieldCode: findField.exportProp || findField.prop,
            fieldName: findField.label,
          }];
        }
        return prev;
      }, []);

      shipperGoodsStockExport({
        ...data,
        exportFields,
      });
    },
    async handleFrozen() {
      await this.checkSelected();

      const includeFrozen = this.selectionList
        .some((item) => item.frozenStatus === FROZEN_STATUS[0].value);
      if (includeFrozen) {
        this.$message({ type: 'warning', message: '已选择中包含已冻结的库存' });
        return;
      }
      this.$refs.frozenDialog.init();
    },
    async  frozenSuccess(form) {
      await this.checkSelected();

      const data = {
        ...form,
        details: this.buildFrozenList(),
      };
      await stockFreeze(data);
      this.$message({ type: 'success', message: '冻结成功' });
      this.getTableData();
    },
    async handleUnfreeze() {
      await this.checkSelected();

      const includeUnfrozen = this.selectionList
        .some((item) => item.frozenStatus === FROZEN_STATUS[1].value);
      if (includeUnfrozen) {
        this.$message({ type: 'warning', message: '已选择中包含未冻结的库存' });
        return;
      }
      await this.$confirm('是否确认解冻库存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      const data = {
        details: this.buildFrozenList(),
      };
      await stockUnFreeze(data);
      this.$message({ type: 'success', message: '解冻成功' });
      this.getTableData();
    },
    async handleQuality() {
      await this.checkSelected();

      const lockedGoods = this.selectionList.filter(({
        lockedNum, frozenNum,
      }) => lockedNum > 0 || frozenNum > 0);

      const haslockedGoods = lockedGoods.length > 0;

      if (haslockedGoods) {
        // 仅支持选择无锁定量的库存进行批量调整
        this.$message({ type: 'warning', message: '请选择无分配量和冻结量的库存' });
        return;
      }

      this.showModifyQualityDialog();
    },
    showModifyQualityDialog() {
      this.$refs.modifyQualityDialog.open();
    },
    handleModifyQuerySuccess() {
      this.getTableData();
    },
    buildFrozenList() {
      return this.selectionList.map(({
        goodsId, locationId, batchNo, goodsQualityCode, stockProductDate,
      }) => ({
        goodsId, locationId, batchNo, goodsQuality: goodsQualityCode, productDate: stockProductDate,
      }));
    },
    handleRecordsClick(row) {
      this.$refs.recordsDialog.open(row);
    },
    async checkSelected() {
      const hasSelected = this.selectionList.length > 0;

      return new Promise((resolve, reject) => {
        if (hasSelected) {
          resolve(hasSelected);
        } else {
          this.$message({ type: 'warning', message: '请先选择需要操作的库存' });
          reject(hasSelected);
        }
      });
    },
    async handleBatchAdjustment() {
      await this.checkSelected();
      const flag = this.selectionList
        .every((item) => item.goodsCode === this.selectionList[0].goodsCode);
      if (!flag) {
        this.$message({ type: 'warning', message: '请勾选同一货品' });
        return;
      }
      const selectList = this.selectionList.map((item) => ({
        ...item,
        goodsQuality: this.GOODS_QUALITY.find((i) => i.label === item.goodsQuality)?.value,
      }));
      this.$refs.qualityAdjustDialog.open(selectList);
    },
    handleBatchQuery() {
      this.$refs.historyAdjustDialog.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}

.option-left {
  float: left;
}

.option-right {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.option-box {
  display: block;
  width: 16px;
  height: 16px;
}

.status-flag {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
</style>
