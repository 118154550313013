<template>
  <nh-drawer
    v-model="visible"
    title="锁定记录"
    destroy-on-close
    size="1112px"
    :track="{
      name_zh: `在库管理/库存管理/库位批次库存查询/抽屉-预占记录`,
      trackName: $route.path
    }"
  >
    <el-form label-width="100">
      <div class="header-component">
        <el-form-item
          v-for="(item) in orderInfoFileds"
          :key="item.prop"
          :label="item.label"
          class="form-item"
        >
          <span>{{ item.formatter ? item.formatter(orderInfo) : orderInfo[item.prop] }}</span>
        </el-form-item>
      </div>
    </el-form>
    <div class="table_header">
      <div class="flex_1">
        预占详情
      </div>
      <div>锁定量合计：{{ orderInfo.lockedAmount }}</div>
    </div>
    <PlTable
      :loading="loading.setRecords"
      :data="records"
      :columns="tableColumns"
    />
    <template #footer>
      <div class="footer">
        <nh-button
          type="grey"
          @click="close"
        >
          {{ `${isEdit ? '取 消': '关 闭'}` }}
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import { mapState } from 'vuex';
import loadingMixin from '@thales/loading';

import { searchBatchStockLocked } from '../../api';
import { orderInfoFileds, tableColumns } from '../fileds';

export default {
  mixins: [loadingMixin],
  data() {
    return {
      visible: false,
      orderInfo: {},
      orderInfoFileds,
      records: [],
      tableColumns,
      loading: {
        setRecords: false,
      },
    };
  },
  computed: {
    ...mapState({
      accountInfo: ({ user }) => user.accountInfo,
    }),
  },
  methods: {
    open(info) {
      this.visible = true;
      this.orderInfo = { ...info };
      this.setRecords();
    },
    async setRecords() {
      this.records = await searchBatchStockLocked({
        goodsId: this.orderInfo.goodsId,
        storehouseId: this.orderInfo.locationId,
        batchNo: this.orderInfo.batchNo,
        goodsQuality: this.orderInfo.goodsQualityCode,
        warehouseCode: this.accountInfo.ext?.warehouseCode,
      });
    },
    onClose() {
      this.orderInfo = {};
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-component {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  .form-item {
    flex: 1 1 percentage(1 / 3);
    padding: 0;
  }
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.table-input {
  display: flex;
  align-items: center;

  .el-form-item {
    margin-bottom: 0;
  }

  .el-input-number {
    width: 60px;
  }
}

.width-100 {
  width: 100%;
}

.footer {
  text-align: right;
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
}

.table_header {
  display: flex;
  margin-bottom: 20px;
}
</style>
