<template>
  <nh-drawer
    v-model="visible"
    :before-close="close"
    destroy-on-close
    size="1111px"
    title="货品批次调整"
  >
    <div
      v-loading="loading.open"
      class="content"
    >
      <div class="goods">
        <p class="goods-info">
          <span>货品编码：{{ form.goodsCode }}</span>
          <span>货主：{{ form.shipperName }}</span>
          <span>货品名称：{{ form.goodsName }}</span>
        </p>
        <div class="table-box">
          <PlTable
            :data="tableData"
            :columns="TABLECOLUMN"
          />
        </div>
      </div>
      <div class="form">
        <div class="form-title">
          <span>调整为</span>
          <nh-button @click="handleSelectAdjust(form)">
            选择历史批次
          </nh-button>
        </div>
        <el-form
          ref="ruleFormRef"
          :model="form"
          label-width="100px"
        >
          <el-row>
            <el-col
              v-for="item in fields"
              :key="item.prop"
              :span="8"
            >
              <el-form-item
                :label="item.label"
                :prop="item.prop"
                v-bind="item.formItemAttrs"
              >
                <nh-form-component :form="form" :field="item" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <template #footer>
      <div class="footer">
        <div>
          <nh-button
            v-loading="loading.submit"
            type="primary"
            @click="handleSubmit"
          >
            确 定
          </nh-button>
          <nh-button type="grey" @click="close">
            关 闭
          </nh-button>
        </div>
      </div>
    </template>
    <HistoryAdjust ref="historyDrawer" @onSelect="handleSelect" />
  </nh-drawer>
</template>

<script setup>
import { defineExpose, ref, defineEmits } from 'vue';
import HistoryAdjust from '../HistoryAdjust/HistoryAdjust.vue';
import useDrawer from './uses/drawer';
import TABLECOLUMN from './fileds';

const emits = defineEmits(['onSucceed']);
const {
  visible,
  loading,
  open,
  close, tableData,
  form, fields,
  onSubmit,
} = useDrawer();

const historyDrawer = ref(null);
const handleSelectAdjust = (data) => {
  if (historyDrawer.value) {
    historyDrawer.value.open(data);
  }
};

const handleSelect = (row) => {
  row.batchAttrFields.forEach((item) => {
    form.value[item.fieldCode] = item.paddingValue;
  });
};

const ruleFormRef = ref(null);
const handleSubmit = async () => {
  if (ruleFormRef.value) {
    await ruleFormRef.value.validate();
    await onSubmit();
    close();
    emits('onSucceed');
  }
};
defineExpose({
  open,
});
</script>
<style lang="scss" scoped>
.goods {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .goods-info {
    display: flex;
    margin-bottom: 8px;

    span {
      min-width: 20%;
    }

    span:not(:last-of-type) {
      padding-right: 24px;
    }
  }

  .table-box {
    flex: 1;
    overflow: auto;
  }
}

.content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  .goods {
    flex: 1;
  }
}

.form {
  .form-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 4px;
    border-bottom: 1px solid #ebeef5;
  }
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #ebeef5;

  & > div {
    padding-right: 30px;
  }
}
</style>
