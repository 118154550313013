<template>
  <el-dialog
    v-model="visible"
    title="货品品质调整"
    :track="{
      name_zh: `在库管理/库存管理/库位批次库存查询/弹窗-货品质量调整`,
      trackName: $route.path
    }"
    @close="handleClose"
  >
    <el-form
      ref="form"
      label-width="130px"
      :model="form"
      :rules="rules"
    >
      <div class="header-component">
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="已选库存包含:"
            >
              {{ selectedQualities }}
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="调整为:"
              prop="newGoodsQuality"
            >
              <el-select
                v-model="form.newGoodsQuality"
              >
                <el-option
                  v-for="item in $dictionaryFiledMap.GOODS_QUALITY"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <nh-button @click="handleCancel">
          取消
        </nh-button>
        <nh-button type="primary" @click="handleSubmit">
          确定
        </nh-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import loadingMixin from '@thales/loading';
import { adjustStockQuality } from '../../api';

export default {
  mixins: [loadingMixin],
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['modifySuccess'],
  data() {
    return {
      form: {
        newGoodsQuality: [],
      },
      visible: false,
      rules: {
        newGoodsQuality: [{ required: true, message: '请选择', trigger: 'change' }],
      },
    };
  },
  computed: {
    selectedQualities() {
      const qualities = [];

      this.list.forEach(({ goodsQuality }) => {
        if (!qualities.includes(goodsQuality)) {
          qualities.push(goodsQuality);
        }
      });

      return qualities.join('、');
    },
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    handleClose() {
      this.$refs.form.resetFields();
    },
    handleCancel() {
      this.close();
    },
    async handleSubmit() {
      await this.$refs.form.validate();
      await this.submit();
      this.close();
      this.$emit('modifySuccess');
    },
    async submit() {
      const details = this.list.map((item) => ({
        goodsId: item.goodsId,
        locationId: item.locationId,
        batchNo: item.batchNo,
        goodsQuality: item.goodsQualityCode,
        productDate: item.stockProductDate,
      }));

      const params = {
        details,
        newGoodsQuality: this.form.newGoodsQuality,
      };

      await adjustStockQuality(params);
    },
  },
};
</script>
