import { ref } from 'vue';

const GoodsList = () => {
  const data = ref([]);
  const setGoods = (list) => {
    data.value = list;
  };
  const removeGoods = () => {
    data.value = [];
  };
  return {
    data,
    setGoods,
    removeGoods,
  };
};

export default GoodsList;
